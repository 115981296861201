:root {
  --primary-color: #1c99ff;
  --primary-light: #aad8ff;
  --primary-dark: #003458;
  --secondary-color: #62875a;
  --secondary-dark: #305a2a;
  --shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
  --success-color: #79a079;
  --navbar-height: 4rem;
}

.App {
  text-align: center;
  z-index: 10;
}

object {
  max-width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  color: var(--secondary-color);
}

.story-section {
  width: clamp(35ch, 90%, 70ch);
  text-align: left;
  background-color: white;
  box-shadow: 0 0 10px 30px rgba(255, 255, 255, 1);
  margin: 4rem;
  color: var(--primary-dark);
  animation: fadeIn 0.5s ease-in forwards;
}

@keyframes fadeIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

h1 {
  font-size: 2rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  height: var(--navbar-height);
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  background-color: var(--primary-color);
  color: white;
  text-shadow: 0 0 4px var(--secondary-color);
  z-index: 1000;
  box-shadow: var(--shadow);
}

.brand {
  font-family: "Copperplate Gothic", serif;
  font-size: 1.2rem;
  width: max-content;
  cursor: pointer;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .navbar .container {
    flex-direction: column;
  }
}

.container {
  max-width: 1000px;
  margin: auto;
  padding: 0 1rem;
}

.section {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  transition: opacity 0.8s linear;
  background-color: white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  transform: translate3d(0, 0, 0);
  scroll-snap-align: start;
  scroll-snap-stop: always;
  width: auto;
}

.text-white {
  color: white;
}

.text-shadow {
  text-shadow: 0 5px 10px var(--secondary-dark);
}

.section h1 {
  text-shadow: 0 0 2px var(--primary-color), 0 0 4px var(--primary-color),
    0 0 6px var(--primary-color), 0 0 8px var(--primary-color),
    0 0 10px var(--primary-color);
  color: white;
}

.main-logo {
  margin-top: 0;
  filter: drop-shadow(0 0 1px var(--primary-color))
    drop-shadow(0 0 2px var(--primary-color))
    drop-shadow(0 0 3px var(--primary-color));
}

.map-logo {
  opacity: 0;
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translateX(-47%);
  width: calc(55vmin);
  filter: drop-shadow(0 0 1px var(--primary-color))
    drop-shadow(0 0 2px var(--primary-color))
    drop-shadow(0 0 3px var(--primary-color));
}

.map-section p {
  margin-top: 50vh;
}

.section1 {
  /*scroll-snap-align: start;*/
  background-color: var(--primary-color);
  opacity: 0;
}

.top-section {
  padding-top: 5rem !important;
}

button {
  font-family: inherit;
  font-size: 1.2rem;
}

.section2 {
  /*scroll-snap-align: start;*/
  background-color: var(--secondary-color);
  opacity: 0;
}

.trans {
  background-color: transparent;
  opacity: 1;
}

.text-primary {
  --primary-color: #72b4ff;
  color: var(--primary-color);
  text-shadow: 2px 2px 2px var(--primary-dark);
}

.text-secondary {
  color: white;
  text-shadow: 0 0 2px var(--secondary-color), 0 0 4px var(--secondary-color),
    0 0 6px var(--secondary-color), 0 0 8px var(--secondary-color),
    0 0 10px var(--secondary-color);
}

.btn {
  background-color: var(--primary-color);
  box-shadow: var(--shadow);
  color: white;
  padding: 1rem 2rem;
  border-radius: 2px;
  cursor: pointer;
  transition: transform 0.5s;
  user-select: none;
  text-decoration: none;
}

.btn:hover {
  transform: scale(0.9);
}

.main-btn {
  width: 13rem;
  margin-bottom: 2rem;
}

.text-xl {
  font-size: 3rem;
  margin: 2rem auto;
}

[data-inviewport="fadeIn"].is-inViewport {
  transition: opacity 3s ease-in 1s;
  opacity: 1;
}

footer {
  scroll-snap-align: end;
  min-height: 10vh;
  background-color: var(--secondary-color);
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.6);
  position: relative;
  padding: 2rem 4rem;
  font-family: "Copperplate Gothic", serif;
  color: var(--primary-dark);
}

footer a {
  color: var(--primary-dark);
}

footer .space-between > .space-between {
  gap: 2rem;
}

@media (max-width: 768px) {
  footer > .flex {
    flex-direction: column;
    gap: 1rem;
  }

  .map-logo {
    top: 28%;
    left: 50%;
    transform: translateX(-47%);
    width: 90vmin;
  }
}

@media (max-width: 320px) {
  .container {
    padding: 0.5rem;
  }

  .section {
    padding: 0.5rem;
  }

  p {
    margin: 2rem 2rem;
  }
}

.form-wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  display: none;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.contact-form {
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translateX(-50%) translateY(-300%);
  z-index: 101;
  background-color: var(--primary-color);
  border-radius: 5px;
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
  max-width: 80vw;
  color: white;
}

.contact-form .flex {
  width: 100%;
  margin-top: -2rem;
  padding: 1rem 0;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-shadow: var(--shadow);
}

.close-modal {
  margin-top: -5px;
  cursor: pointer;
}

.close-modal:hover {
  color: var(--secondary-color);
}

.contact-form input {
  text-align: center;
  font-family: inherit;
  font-size: 1.5rem;
  margin: 0 0 1rem;
}

.contact-form button {
  border: 0;
  border-radius: 5px;
  background-color: var(--success-color);
}

.nav-link {
  cursor: pointer;
}

.success {
  background-color: var(--success-color);
}

.info {
  background-color: #af90fd;
}

.danger {
  background-color: #ff47c4;
}

.toast {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: auto;
  font-family: monospace;
  z-index: 9999;
  padding: 0;
}

.toast-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;
}

.toast-container-item {
  border: var(--primary-color) solid 1px;
  margin: 5px auto;
  padding: 2rem;
  border-radius: 7px;
  max-width: 100%;
  min-height: 3rem;
  word-wrap: break-word;
  box-shadow: var(--shadow);
  color: white;
}

@media (min-width: 768px) {
  .toast-container-item {
    min-width: 80vw;
  }
}

.toast-close {
  cursor: pointer;
  margin-left: auto;
  /*margin-right: 10px;*/
  margin-top: -1.5rem;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: 800;
}

.service-radio-group {
  display: flex;
  gap: 0.25rem 1rem;
  flex-direction: column;
  margin: 0 auto;
  width: max-content;
}

.service-radio-input-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.service-radio-group input {
  margin-right: 1rem;
  margin-top: 0.25rem;
}

legend {
  font-family: "Copperplate Gothic", serif;
  font-size: 1.1rem;
  padding: 1rem 0;
}

.form-heading {
  padding-bottom: 1rem;
}

.name-email-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .contact-form {
    top: calc(var(--navbar-height) + 1rem);
    max-height: calc(100vh - (var(--navbar-height) + 2rem));
  }
}
