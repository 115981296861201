.card {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: .5rem;
  height: 37vw;
  width: 37vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  margin: 1rem;
}

.card h1 {
  font-size: 1rem;
}

.before-after-wrapper {
  overflow-y: auto;
  max-width: 100vw;
  height: 100%;
  backdrop-filter: blur(2px);
}

.before-after-pics {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  margin: auto;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  width: max-content;
  overflow-x: auto;
}

@media (min-width: 768px) {
  .before-after-wrapper {
    /*max-height: 30vh;*/
    bottom: 5vh
  }
  .card {
    height: 20vw;
    width: 20vw;
  }
}
