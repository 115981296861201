@font-face {
    font-family: 'Copperplate Gothic';
    src: url('./fonts/COPGOTHB.TTF')
}

@font-face {
    font-family: 'Garamond';
    src: url('./fonts/garr45w.ttf')
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
}

html {
    scroll-behavior: smooth;
    scroll-snap-type: y proximity;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Garamond', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

.w-full {
    width: 100%;
}

.w-screen {
    width: 100vw;
}

/*input {*/
/*    border-radius: 7px;*/
/*    box-shadow: var(--shadow);*/
/*    display: block;*/
/*}*/

/*form {*/
/*    min-width: 250px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/